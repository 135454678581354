import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const YouTube = makeShortcode("YouTube");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In the following videos we'll create a `}<a parentName="p" {...{
        "href": "https://commercejs.com/"
      }}>{`Commerce.js`}</a>{` powered store with `}<a parentName="p" {...{
        "href": "https://svelte.dev/"
      }}>{`Svelte`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://sapper.svelte.dev/"
      }}>{`Sapper`}</a>{`.`}</p>
    <p>{`We will fetch products, and their categories, along with creating pages for each, and an index page to show everything.`}</p>
    <ul>
      <li parentName="ul">{`📺 `}<a parentName="li" {...{
          "href": "https://www.youtube.com/playlist?list=PLs2PzMqLzi7XkF-_672sFOVduEORZJTOQ"
        }}>{`See full playlist`}</a></li>
      <li parentName="ul">{`🛠 `}<a parentName="li" {...{
          "href": "https://github.com/notrab/sapper-commercejs-example"
        }}>{`Browse code on GitHub`}</a></li>
    </ul>
    <h2>{`Part 1`}</h2>
    <p>{`Let's get setup with the initial Sapper + Svelte boilerplate, and customize it to our needs.`}</p>
    <YouTube youTubeId="mE6f1rlQX1o" mdxType="YouTube" />
    <h2>{`Part 2`}</h2>
    <p>{`In this video we'll install and setup Commerce.js `}{`—`}{` You'll need an account to continue, or you can continue with the demo public API key: `}<inlineCode parentName="p">{`pk_184625ed86f36703d7d233bcf6d519a4f9398f20048ec`}</inlineCode>{`.`}</p>
    <YouTube youTubeId="n_T5escg_Pg" mdxType="YouTube" />
    <h2>{`Part 3`}</h2>
    <p>{`In this video we'll use the async `}<inlineCode parentName="p">{`preload`}</inlineCode>{` function to fetch our merchant information, categories, and products for `}<inlineCode parentName="p">{`index.svelte`}</inlineCode>{`.`}</p>
    <YouTube youTubeId="BZYCg4Rmlx8" mdxType="YouTube" />
    <h2>{`Part 4`}</h2>
    <p>{`In this video we'll go ahead and create the products index page at `}<inlineCode parentName="p">{`routes/products/index.svelte`}</inlineCode>{`.`}</p>
    <YouTube youTubeId="ixlXkQH2xZU" mdxType="YouTube" />
    <h2>{`Part 5`}</h2>
    <p>{`In this video we'll create the index page for our categories at `}<inlineCode parentName="p">{`routes/categories/index.svelte`}</inlineCode>{`.`}</p>
    <YouTube youTubeId="Fqwfpp1xHWA" mdxType="YouTube" />
    <h2>{`Part 6`}</h2>
    <p>{`In this video we'll handle creating pages for all our categories using the `}<inlineCode parentName="p">{`[slug]`}</inlineCode>{`.`}</p>
    <YouTube youTubeId="TOvinnD7uGc" mdxType="YouTube" />
    <h2>{`Part 7`}</h2>
    <p>{`Just like we did for the category pages, let's handle creating pages for each of our products using the `}<inlineCode parentName="p">{`[permalink]`}</inlineCode>{`.`}</p>
    <YouTube youTubeId="n9AWs0rnnl8" mdxType="YouTube" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      